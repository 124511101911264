import './App.css';

export default function App() {
  return (
<div className="font-narrow items-center text-center">
<div>
<h1 className="text-6xl">медиашиза.ру</h1>
<h2 className="text-4xl">очень важно</h2>
<h3 className="text-2xl">скучаете? я тоже, но увидимся в этом году :)</h3>
    <a
          href="https://santik.su/"
          className="mt-16 bg-black text-white rounded-lg w-auto text-center p-4 h-8"
          target="_self"
        >
          На сайт создателя ›
        </a>
</div>
</div>
    );
    }
